/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getRouteById } from "../../../actions/routeActions";
import LoadingComponent from "../../../components/LoadingComponent";
import moment from "moment";
import RouteMap from "../../RouteMap";

const AdminSingleRouteViewEachPassenger = () => {
  const routes = useSelector((state) => state.routes);
  const service = useSelector((state) => state.service);
  const students = useSelector((state) => state.students);
  const dispatch = useDispatch();
  const params = useParams();

  const locationUrl = useLocation();
  const queryParams = new URLSearchParams(locationUrl.search);
  const userParam = queryParams.get("cusId");
  const shiftParam = queryParams.get("shift");

  const { loading, routeDetails } = routes;

  useEffect(() => {
    dispatch(getRouteById(params.id));
  }, [params]);

  const [userDetails, setUserDetails] = useState()
  useEffect(() => {
    if (userParam && shiftParam){
      if (shiftParam === "AM"){
        const find =  routeDetails?.customerDetailsAM?.find((x)=> x.customerId.toString() === userParam.toString())
        const customerFound = students?.routePassengerList?.find(
          (customer) => customer.id.toString() === userParam.toString()
        );
        setUserDetails({address: find, customer: customerFound})
      }
      else if (shiftParam === "PM"){
        const find =  routeDetails?.customerDetailsPM?.find((x)=> x.customerId.toString() === userParam.toString())
        const customerFound = students?.routePassengerList?.find(
          (customer) => customer.id.toString() === userParam.toString()
        );
        setUserDetails({address: find, customer: customerFound})
      }
      else if (shiftParam === "MID"){
        const find =  routeDetails?.customerDetailsMID?.find((x)=> x.customerId.toString() === userParam.toString())
        const customerFound = students?.routePassengerList?.find(
          (customer) => customer.id.toString() === userParam.toString()
        );
        setUserDetails({address: find, customer: customerFound})
      }
    }
  }, [routeDetails, userParam, shiftParam]);

  const chicagoDateOptions = {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const chicagoDate = new Date().toLocaleDateString(
    "en-US",
    chicagoDateOptions
  );
  const parts = chicagoDate.split("/");
  const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
  // console.log(userDetails?.address?.pickUpAddress)
  return (
    <>
      {loading && <LoadingComponent />}
      <div className="content-wrapper border-top-0 client-detail-wrapper">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mb-xl-0 mb-lg-4 mb-md-0">
            <div className="card bg-white border-0 b-shadow-4">
              <div className="card-header bg-white border-0 text-capitalize d-flex justify-content-between p-20">
                <h4 className="f-18 f-w-500 mb-0"> Route</h4>
              </div>

              <div className="row">
               <div className="col-md-4">
                  <div className="card-body  border rounded m-1">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block border-bottom-grey mb-2">
                      <p className="mb-0 text-dark f-14 w-30 text-capitalize">
                        Route#
                      </p>
                      <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
                        {routeDetails?.routeNumber}{" "}
                        {routeDetails?.routeNumberReturn &&
                          "( Return )"}
                        <strong>
                          {"\u00a0" +
                            "\u00a0" +
                            "\u00a0" +
                            "Shift Time:" +
                            "\u00a0"}
                        </strong>
                        {/* {current?.shiftTime} */}
                        {shiftParam}
                      </p>
                    </div>
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block border-bottom-grey mb-2">
                      <p className="mb-0 text-dark f-14 w-30 text-capitalize">
                      Customer Name
                      </p>
                      <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
                      {userDetails?.customer?.firstName+" "+userDetails?.customer?.lastName}
                      </p>
                    </div>
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block border-bottom-grey mb-2">
                      <p className="mb-0 text-dark f-14 w-30 text-capitalize">
                        Type
                      </p>
                      <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
                        {service?.service &&
                          service?.service?.length > 0 &&
                          service?.service?.find(
                            (x) => x.id == userDetails?.address?.type
                          )?.name}
                      </p>
                    </div>
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block border-bottom-grey mb-2">
                      <p className="mb-0 text-dark f-14 w-30 text-capitalize">
                        Pickup
                      </p>
                      <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
                        {userDetails?.address?.pickUpAddress?.address}
                        <br />
                        {moment(userDetails?.address?.pickUpDate).format(
                          "DD MMMM, YYYY"
                        )}{" "}
                        @{" "}
                        {moment(userDetails?.address?.pickUpTime, "hh:mm").format(
                          " hh:mm a"
                        )}
                      </p>
                    </div>
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block border-bottom-grey mb-2">
                      <p className="mb-0 text-dark f-14 w-30 text-capitalize">
                        Drop off
                      </p>
                      <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
                        {userDetails?.address?.dropOffAddress?.address}
                        <br />
                        {moment(userDetails?.address?.dropOffDate).format(
                          "DD MMMM, YYYY"
                        )}{" "}
                        @{" "}
                        {moment(userDetails?.address?.dropOffTime, "hh:mm").format(
                          "hh:mm a"
                        )}
                      </p>
                    </div>
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0 text-dark f-14 w-30 text-capitalize">
                        Notes
                      </p>
                      <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
                        {userDetails?.address?.dispatchNotes
                          ? userDetails?.address?.dispatchNotes
                          : " - "}
                      </p>
                    </div>
                  </div>
                </div>
               <div className="col-md-8">

                {userDetails &&
                <RouteMap center={{lat: userDetails?.address?.pickUpAddress?.latitude , lng: userDetails?.address?.pickUpAddress?.longitude}} targetPosition={{lat: userDetails?.address?.dropOffAddress?.latitude , lng: userDetails?.address?.dropOffAddress?.longitude}}/> 
                }
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSingleRouteViewEachPassenger;
